import api from "../../Utils/Api";

class SectionService {
  getAll(moduleId) {
    return api.getAxios().get(`/module_sections?course_module_id=${moduleId}`);
  }

  get(id) {
    return api.getAxios().get(`/module_sections/${id}`);
  }

  create(data) {
    return api.getAxios().post("/module_sections", data);
  }

  update(id, data) {
    return api.getAxios().put(`/module_sections/${id}`, data);
  }

  delete(id) {
    return api.getAxios().delete(`/module_sections/${id}`);
  }

  findBy(moduleId, field, value) {
    return api.getAxios().get(`/module_sections?course_module_id=${moduleId}&${field}=${value}`);
  }
}

export default new SectionService();