import '../../../node_modules/react-simple-tree-menu/dist/main.css';

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TreeMenu from 'react-simple-tree-menu';

import courseService from '../Course/CourseService';
import moduleService from '../Module/ModuleService';

function CourseMenu(props) {
  const [tree, setTree] = useState([]);
  const history = useHistory();

  useEffect(() => {
    courseService.getAll().then(async response => {
      const coursesTree = [];

      await Promise.all(response.data.map(async course => {
        const modulesResp = await moduleService.getAll(course.id);
        const nodes = [];
        modulesResp.data.forEach(module => {
          const deepNodes = [];
          module.items.forEach(section => {
            deepNodes.push({
              key: section.id,
              label: section.name,
              url: '',
              nodes: [{
                key: 'kc-' + section.id,
                label: 'Knowledge Checks',
                url: `/sections/${section.id}/knowledge_checks`
              }, {
                key: 'vid-' + section.id,
                label: 'Videos',
                url: `/sections/${section.id}/videos`
              }]
            });
          });
          nodes.push({
            key: module.id,
            label: module.name,
            url: `/modules/${module.id}/sections`,
            nodes: deepNodes
          });
        });
        coursesTree.push({
          key: course.id,
          label: course.name,
          order: course.order,
          url: `/courses/${course.id}/modules`,
          nodes: nodes
        });
      }));

      // coursesTree.sort(function (a, b) {
      //   return ('' + a.label).localeCompare(b.label);
      // });

      coursesTree.sort(function (a, b) {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      })

      setTree([{
        key: 'courses-root',
        label: 'Courses',
        url: '/courses',
        nodes: coursesTree
      }]);
    });
  }, []);

  function handleClick(node) {
    if (node.url && node.url.length > 0) {
      history.push(node.url);
    }
  }

  return (
    <div className="tree-menu">
      <TreeMenu data={tree} hasSearch={false} onClickItem={handleClick} />
    </div>
  );
}

export default CourseMenu;