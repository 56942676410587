import 'react-bootstrap-typeahead/css/Typeahead.css';

import React, { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row, Table } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import courseService from '../Course/CourseService';

function CourseEdit(props) {
  const history = useHistory();

  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState(0);
  const [name, setName] = useState('');
  const [order, setOrder] = useState(0);
  const [draft, setDraft] = useState(false);
  const [show] = useState(true);

  const handleClose = () => history.goBack();
  const handleName = (e) => setName(e.target.value);
  const handleOrder = (e) => setOrder(+e.target.value);
  const handleDraft = (e) => setDraft(!draft);

  const handleSave = () => {
    if (course) {
      courseService.update(course, {
        course: {
          name: name,
          order: order,
          draft: draft
        }
      }).then(r => {
        history.push('/courses');
      });
    } else {
      courseService.create({
        course: {
          name: name,
          draft: draft,
          order: order
        }
      }).then(r => {
        history.push('/courses');
      });
    }
  }

  function navigateTo(route) {    history.push(route);
  }

  useEffect(() => {
    courseService.getAll().then(response => {
      setCourses(response.data);
    });
    if (props.match.params.id) {
      courseService.get(props.match.params.id).then(response => {
        setCourse(response.data.id);
        setName(response.data.name);
        setOrder(response.data.order);
        setDraft(response.data.draft);
      });
    }
  }, [props.match.params.id]);

  return (
    <div id="course-edit">
      <div id="actions" className="float-end">
        <Button variant="flat" onClick={() => navigateTo('/courses/add')}>Add Course</Button>
      </div>
      <Table hover>
      <thead>
        <tr>
          <th>Course</th>
          <th>Order</th>
          <th>Last Update</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {courses.map((course) => {
          return (
            <tr key={ course.id }>
              <td><strong>{ course.name }</strong></td>
              <td>{ course.order }</td>
              <td>{ new Date(course.updated_at).toLocaleDateString() }</td>
              <td className="r-actions">
                <Link to={'/courses/' + course.id + '/edit'}>Edit</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ course ? 'Edit' : 'New' } Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <FloatingLabel controlId="name" label="Course Name">
                  <Form.Control value={name} onChange={handleName} />
                </FloatingLabel>
              </Col>

              <Col>
                <FloatingLabel controlId="order" label="Order">
                  <Form.Control value={order} type={'number'} onChange={handleOrder} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mt-2" controlId="formBasicCheckbox">
                  <Form.Check className={`cursor-pointer separate-r `} onChange={handleDraft} checked={draft} type="checkbox" label="Draft" />
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Button variant="flat" style={{"width": '100%'}} onClick={handleSave}>
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
 
export default CourseEdit;