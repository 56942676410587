import React, { useContext, useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import courseService from '../Course/CourseService';
import modulesService from './ModuleService';
import { getUser } from '../../Utils/Common';
import { Context } from '../../Utils/Store';

function Module(props) {
  const history = useHistory();
  const currentUser = getUser();
  const [modules, setModules] = useState([{}]);
  const [isEmpty, setIsEmpty] = useState(true);
  const [parentCourse, setParentCourse] = useState('');

  const [state, dispatch] = useContext(Context);
  
  useEffect(() => {
    if (state.search.length > 0) {
      modulesService.findBy(props.match.params.id, 'search', state.search).then(response => {
        setModules(response.data);
        setIsEmpty(response.data.length === 0);
      }).catch(e => {
        dispatch({type: 'SET_ERROR', payload: e});
      });
    } else {
      modulesService.getAll(props.match.params.id).then(response => {
        setModules(response.data);
        setIsEmpty(response.data.length === 0);
      });
    }
    courseService.get(props.match.params.id).then(r => setParentCourse(r.data.name));
  }, [props.match.params.id, currentUser.id, state.search, dispatch]);
  
  function navigateTo(route) {
    history.push(route);
  }

  return (
    <div className="modules">
      <div id="title" className="float-start"><h1>Modules - {parentCourse}</h1></div>
      <div id="actions" className="float-end">
        <Button variant="flat" onClick={() => navigateTo('/courses/' + props.match.params.id + '/modules/add')}>Add Module</Button>
      </div>
      <div className="clearfix"></div>
      <Table hover hidden={isEmpty}>
      <thead>
        <tr>
          <th>Module</th>
          <th>Hours</th>
          <th>Order</th>
          <th>Last Update</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {modules.map((module) => {
          return (
            <tr className={'r-' + module.id} key={ module.id }>
              <td><strong>{ module.name }</strong></td>
              <td>{ module.length }</td>
              <td>{ module.order }</td>
              <td>{ new Date(module.updated_at).toLocaleDateString() }</td>
              <td className="r-actions">
                <Link to={'/courses/' + props.match.params.id + '/modules/' + module.id + '/edit'}>Edit</Link>
                <Link to={'/modules/' + module.id + '/sections'}>Sections</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
      </Table>
      <div className="no-data" hidden={!isEmpty}>
        <img alt="modules icon" src="/images/sections_icon.svg" />
        <div className="no-data-actions">
          <div>
            <strong>No modules</strong>
            <span>Get started by creating a new module</span>
          </div>
          <img alt="arrow poiting to add knowledge check" src="/images/arrow_indicator.svg" />
        </div>
      </div>
    </div>
  );
}
 
export default Module;