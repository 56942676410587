import './Login.css';

import axios from 'axios';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { setUserSession } from '../../Utils/Common';

function Login(props) {
  const history = useHistory();
  const email = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
 
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/login.json`, { user: { email: email.value, password: password.value } }).then(response => {
      setLoading(false);
      setUserSession(response.data.user.token, response.data.user);
      props.loginCallback(true);
      history.push('/users/dashboard');
    }).catch(error => {
      setLoading(false);
      if (!error.response) {
        console.log(error);
        setError('Application error. Please try again later.')
      } else if (error.response.status === 422) {
        setError('Error: email/password ' + error.response.data.errors['email/password']);
      }
      else {
        setError("Authentication server is failing. Please try again later.");
      }
    });
  }
 
  return (
    <div id="login-screen">
      <div className="login-logo"></div>
      <h1 className="login-header">Sign in to your account</h1>
      <div className="email-input">
        <input name="email" type="text" placeholder="Email Address" autoComplete="new-password" {...email} />
      </div>
      <div className="password-input">
        <input type="password" {...password} placeholder="Password" autoComplete="new-password" />
      </div>
      <div className="login-error">
      {error && <small>{error}</small>}
      </div>
      <div className="remember-me-input">
        <div className="float-start">
          <input type="checkbox" id="loginCheckbox" className="remember-me" />
          <label title="" htmlFor="loginCheckbox" className="remember-me-label">Remember me</label>
        </div>
        <div className="float-end">
          {/* TODO: implement Admin own forgot password page */}
          <a href="https://www.bb.kambda.com/reset-password"><strong>Forgot your password?</strong></a>
        </div>
        <div className="clearfix"></div>
      </div>
      <div className="submit-button">
        <Button variant="flat" onClick={handleLogin} disabled={loading}>{loading ? 'Loading...' : 'Login'}</Button>
      </div>
    </div>
  );
}
 
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
 
  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}
 
export default Login;