import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Chart from "react-google-charts";

import userService from './UserService';

function UserDashboard(props) {
  const [dashboardData, setDashboardData] = useState({});

  useEffect(() => {
    userService.dashboard().then(r => setDashboardData(r.data));
  }, []);
 
  return (
    <div id="user-dashboard">
      <Row>
        <Col>
          Users logged in now: <strong className="ds-complement-500">{dashboardData.sign_in_count}</strong>
        </Col>
      </Row>
      <Row>
        <Col>
          Users registered: <strong className="ds-complement-500">{dashboardData.total_count}</strong>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>Times of the day when users log in</h2>
          <Chart
            chartType="AreaChart"
            loader={<div>Loading Chart</div>}
            data={dashboardData.sign_in_time_count}
            options={{
              hAxis: { title: 'Time of day', titleTextStyle: { color: '#333' } },
              vAxis: { minValue: 0 }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>Top modules by activity</h2>
          <Chart
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={dashboardData.modules_activity_count}
          />
        </Col>
      </Row>
    </div>
  );
}

export default UserDashboard;