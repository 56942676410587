import React, {useContext, useEffect, useState} from 'react';
import {Table, Button} from 'react-bootstrap';
import {Link, useHistory} from 'react-router-dom';

import userService from './UserService';
import {Context} from '../../Utils/Store';

function User(props) {
    const history = useHistory();
    const [users, setUsers] = useState([]);
    const [isEmpty, setIsEmpty] = useState(true);

    const [state, dispatch] = useContext(Context);

    useEffect(() => {
        if (state.search.length > 0) {
            userService.findBy('search', state.search).then(response => {
                setUsers(response.data);
                setIsEmpty(response.data.length === 0);
            }).catch(e => {
                dispatch({type: 'SET_ERROR', payload: e});
            });
        } else {
            userService.getAll().then(response => {
                setUsers(response.data);
                setIsEmpty(response.data.length === 0);
            });
        }
    }, [state.search, dispatch]);

    function navigateTo(route) {
        history.push(route);
    }

    return (
        <div>
            <div id="actions" className="d-flex justify-content-end" hidden={isEmpty}>
                <Button className={`ms-2`} variant="flat" onClick={() => navigateTo('/users/add')}>Add User</Button>
                <Button className={`ms-2`} variant="flat" onClick={() => navigateTo('/users/onboarding')}>Onboarding Codes</Button>
            </div>
            <Table hover hidden={isEmpty}>
                <thead>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email Address</th>
                    <th>Organization</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {users.map((user) => {
                    return (
                        <tr key={user.id}>
                            <td><strong>{user.first_name}</strong></td>
                            <td><strong>{user.last_name}</strong></td>
                            <td>{user.email}</td>
                            <td>{user.schools.length > 0 ? user.schools[0].name : '-'}</td>
                            <td><Link to={'/users/' + user.id + '/edit'}>Edit</Link></td>
                        </tr>
                    );
                })}
                </tbody>
            </Table>
            <div className="no-data" hidden={!isEmpty}>
                <img alt="users icon" src="/images/student_icon.svg"/>
                <div className="no-data-actions">
                    <Button variant="flat" onClick={() => navigateTo('/users/add')}>Add User</Button>
                </div>
            </div>
        </div>
    );
}

export default User;