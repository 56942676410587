import api from "../../Utils/Api";

class CourseService {
  getAll() {
    return api.getAxios().get("/courses?all=1");
  }

  get(id) {
    return api.getAxios().get(`/courses/${id}`);
  }

  create(data) {
    return api.getAxios().post("/courses", data);
  }

  update(id, data) {
    return api.getAxios().put(`/courses/${id}`, data);
  }

  delete(id) {
    return api.getAxios().delete(`/courses/${id}`);
  }

  findBy(field, value) {
    return api.getAxios().get(`/courses?all=1&${field}=${value}`);
  }
}

export default new CourseService();