import api from "../../Utils/Api";

class UserService {
  getAll() {
    return api.getAxios().get("/users");
  }

  get(id) {
    return api.getAxios().get(`/users/${id}`);
  }

  create(data) {
    return api.getAxios().post("/users", data);
  }

  sendUserFile(userFile, school_id) {
    return api.getAxios().post(`/school/${school_id}/users/import`, userFile, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }

  update(id, data) {
    return api.getAxios().put(`/users/${id}`, data);
  }

  delete(id) {
    return api.getAxios().delete(`/users/${id}`);
  }

  setSchools(id, schools) {
    return api.getAxios().post(`/users/${id}/set_schools`, {
      "user": {
          "schools": schools
      }
    });
  }

  findBy(field, value) {
    return api.getAxios().get(`/users?${field}=${value}`);
  }

  dashboard() {
    return api.getAxios().get(`/users/dashboard`);
  }
}

export default new UserService();