import React, { useContext, useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import courseService from './CourseService';
import { Context } from '../../Utils/Store';

function Course(props) {
  const history = useHistory();
  const [courses, setCourses] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);

  const [state, dispatch] = useContext(Context);
  
  useEffect(() => {
    if (state.search.length > 0) {
      courseService.findBy('search', state.search).then(response => {
        setCourses(response.data);
        setIsEmpty(response.data.length === 0);
      }).catch(e => {
        dispatch({type: 'SET_ERROR', payload: e});
      });
    } else {
      courseService.getAll().then(response => {
        setCourses(response.data);
        setIsEmpty(response.data.length === 0);
      });
    }
  }, [state.search, dispatch]);

  function navigateTo(route) {
    history.push(route);
  }

  return (
    <div>
      <div id="actions" className="float-end">
        <Button variant="flat" onClick={() => navigateTo('/courses/add')}>Add Course</Button>
      </div>
      <div className="clearfix"></div>
      <Table hidden={isEmpty} hover>
      <thead>
        <tr>
          <th>Course</th>
          <th>Order</th>
          <th>Draft</th>
          <th>Last Update</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {courses.map((course) => {
          return (
            <tr key={ course.id }>
              <td><strong>{ course.name }</strong></td>
              <td>{ course.order }</td>
              <td>{ course.draft ? 'Yes' : 'No' }</td>
              <td>{ new Date(course.updated_at).toLocaleDateString() }</td>
              <td className="r-actions">
                <Link to={'/courses/' + course.id + '/edit'}>Edit</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
      </Table>
      <div className="no-data" hidden={!isEmpty}>
        <img alt="courses icon" src="/images/sections_icon.svg" />
        <div className="no-data-actions">
          <div>
            <strong>No courses</strong>
            <span>Get started by creating a new course</span>
          </div>
          <img alt="arrow poiting to add user" src="/images/arrow_indicator.svg" />
        </div>
      </div>
    </div>
  );
}
 
export default Course;