import React, { useContext, useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import moduleService from '../Module/ModuleService';
import sectionsService from './SectionService';
import { Context } from '../../Utils/Store';

function Section(props) {
  const history = useHistory();
  const [sections, setSections] = useState([{}]);
  const [isEmpty, setIsEmpty] = useState(true);
  const [parentModule, setParentModule] = useState('');

  const [state, dispatch] = useContext(Context);
  
  useEffect(() => {
    if (state.search.length > 0) {
      sectionsService.findBy(props.match.params.id, 'search', state.search).then(response => {
        setSections(response.data);
        setIsEmpty(response.data.length === 0);
      }).catch(e => {
        dispatch({type: 'SET_ERROR', payload: e});
      });
    } else {
      sectionsService.getAll(props.match.params.id).then(response => {
        setSections(response.data);
        setIsEmpty(response.data.length === 0);
      });
    }
    moduleService.get(props.match.params.id).then(r => setParentModule(r.data.name));
  }, [props.match.params.id, state.search, dispatch]);

  function navigateTo(route) {
    history.push(route);
  }

  return (
    <div className="sections">
      <div id="title" className="float-start"><h1>Sections - {parentModule}</h1></div>
      <div id="actions" className="float-end">
        <Button variant="flat" onClick={() => navigateTo('/modules/' + props.match.params.id + '/sections/add')}>Add Section</Button>
      </div>
      <div className="clearfix"></div>
      <Table hover hidden={isEmpty}>
      <thead>
        <tr>
          <th>Section</th>
          <th>Videos</th>
          <th>Knowledge Checks</th>
          <th>Order</th>
          <th>Last Update</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {sections.map((section) => {
          return (
            <tr className={'r-' + section.id} key={ section.id }>
              <td><strong>{ section.name }</strong></td>
              <td>{ section.items ? section.items.length : 0 }</td>
              <td>{ section.knowledge_checks ? section.knowledge_checks.length : 0 }</td>
              <td>{ section.order }</td>
              <td>{ new Date(section.updated_at).toLocaleDateString() }</td>
              <td className="r-actions">
                <Link to={'/modules/' + props.match.params.id + '/sections/' + section.id + '/edit'}>Edit</Link>
                <Link to={'/sections/' + section.id + '/videos'}>Videos</Link>
                <Link to={'/sections/' + section.id + '/knowledge_checks'}>Knowledge Checks</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
      </Table>
      <div className="no-data" hidden={!isEmpty}>
        <img alt="modules icon" src="/images/sections_icon.svg" />
        <div className="no-data-actions">
          <div>
            <strong>No sections</strong>
            <span>Get started by creating a new section</span>
          </div>
          <img alt="arrow poiting to add knowledge check" src="/images/arrow_indicator.svg" />
        </div>
      </div>
    </div>
  );
}
 
export default Section;