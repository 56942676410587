import api from "../../Utils/Api";

class CertificateService {
  getAll() {
    return api.getAxios().get("/user_certificates?all=1");
  }

  get(id) {
    return api.getAxios().get(`/certificates/${id}`);
  }

  create(data) {
    return api.getAxios().post("/certificates", data);
  }

  update(id, data) {
    return api.getAxios().put(`/certificates/${id}`, data);
  }

  delete(id) {
    return api.getAxios().delete(`/certificates/${id}`);
  }

  findBy(field, value) {
    return api.getAxios().get(`/certificates?${field}=${value}`);
  }

  downloadCertificates(link){
    return api.getAxios().get(link,{
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
      }
    });
  }

}

export default new CertificateService();