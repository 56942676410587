import React, { useContext, useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import sectionService from '../Section/SectionService';
import kcsService from './KnowledgeCheckService';
import { Context } from '../../Utils/Store';

function Video(props) {
  const history = useHistory();
  const [knowledgeChecks, setVideos] = useState([{}]);
  const [isEmpty, setIsEmpty] = useState(true);
  const [parentSection, setParentSection] = useState('');

  const [state, dispatch] = useContext(Context);
  
  useEffect(() => {
    if (state.search.length > 0) {
      kcsService.findBy(props.match.params.id, 'search', state.search).then(response => {
        setVideos(response.data);
        setIsEmpty(response.data.length === 0);
      }).catch(e => {
        dispatch({type: 'SET_ERROR', payload: e});
      });
    } else {
      kcsService.getAll(props.match.params.id).then(response => {
        setVideos(response.data);
        setIsEmpty(response.data.length === 0);
      });
    }
    sectionService.get(props.match.params.id).then(r => setParentSection(r.data.name));
  }, [props.match.params.id, state.search, dispatch]);

  function navigateTo(route) {
    history.push(route);
  }

  return (
    <div className="knowledge-checks">
      <div id="title" className="float-start"><h1>Knowledge Checks - {parentSection}</h1></div>
      <div id="actions" className="float-end">
        <Button variant="flat" onClick={() => navigateTo('/sections/' + props.match.params.id + '/knowledge_checks/add')}>Add Knowledge Check</Button>
      </div>
      <div className="clearfix"></div>
      <Table hover hidden={isEmpty}>
      <thead>
        <tr>
          <th>Knowledge Check Name</th>
          <th>Last Update</th>
          <th>Items</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {knowledgeChecks.map((kcheck) => {
          return (
            <tr className={'r-' + kcheck.id} key={ kcheck.id }>
              <td><strong>{ kcheck.name }</strong></td>
              <td>{ new Date(kcheck.updated_at).toLocaleDateString() }</td>
              <td>{ kcheck.knowledge_check_questions_options_answers ? kcheck.knowledge_check_questions_options_answers.length : 0 }</td>
              <td className="r-actions">
                <Link to={'/sections/' + props.match.params.id + '/knowledge_checks/' + kcheck.id + '/edit'}>Edit</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
      </Table>
      <div className="no-data" hidden={!isEmpty}>
        <img alt="knowledge checks icon" src="/images/knowledge_checks_icon.svg" />
        <div className="no-data-actions">
          <div>
            <strong>No knowledge checks</strong>
            <span>Get started by creating a new knowledge check</span>
          </div>
          <img alt="arrow poiting to add knowledge check" src="/images/arrow_indicator.svg" />
        </div>
      </div>
    </div>
  );
}
 
export default Video;