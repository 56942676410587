import React, { useContext } from 'react';
import { Col, Dropdown, Form, InputGroup, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { BsSearch } from "react-icons/bs";

import { Context } from '../../Utils/Store';
import { getUser, removeUserSession } from '../../Utils/Common';

function UserWidget(props) {
  const user = getUser();
  const history = useHistory();
  const [state, dispatch] = useContext(Context);

  const handleSearch = (e) => {
    dispatch({type: 'SET_SEARCH', payload: e.target.value});
    if (state.error) {
      console.log('Error in Store context: ', state.error);
    }
  }

  const handleLogout = () => {    
    removeUserSession();
    props.loginCallback(false);
    history.push('/login');
  }

  return user ? (
    <div className="user-widget">
    <Row>
      <Col sm="12" md="12" lg="8" xl="9">
        <Row>
          <Col sm="12" md="12" lg="12" xl="6">
            <h1><span>Welcome,</span>&nbsp;<strong>{user.first_name}</strong></h1>
          </Col>
          <Col sm="12" md="12" lg="12" xl="6">
            <Form>
              <Form.Group className="mb-3" controlId="globalSearch">
                <InputGroup>
                  <Form.Control type="text" placeholder="Search" onChange={handleSearch} />
                  <InputGroup.Text>
                    <BsSearch/>
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Col>
      <Col sm="12" md="12" lg="4" xl="3">
        <div id="user-profile-widget" className="float-end">
          <Dropdown align="end">
            <Dropdown.Toggle id="user-profile-dropdown">
              <span>
                {user.first_name} {user.last_name}
              </span>
              <img className="profile-image"
                src={user.image ? user.image : '/images/user_profile.svg'}
                alt="user profile"
                referrerPolicy="no-referrer"/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Col>
    </Row>
    </div>
  ) : null;
}

export default UserWidget;