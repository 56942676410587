import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useState, useEffect } from 'react';
import { Container, Button, Col, Row } from 'react-bootstrap';
import { BrowserRouter, NavLink, Redirect, Switch } from 'react-router-dom';

import Certificate from './Components/Certificate';
import CertificateEdit from './Components/Certificate/CertificateEdit';
import Course from './Components/Course';
import CourseEdit from './Components/Course/CourseEdit';
import KnowledgeCheck from './Components/KnowledgeCheck';
import KnowledgeCheckEdit from './Components/KnowledgeCheck/KnowledgeCheckEdit';
import Login from './Components/Login';
import Module from './Components/Module';
import ModuleEdit from './Components/Module/ModuleEdit';
import Organization from './Components/Organization';
import OrganizationEdit from './Components/Organization/OrganizationEdit';
import Section from './Components/Section';
import SectionEdit from './Components/Section/SectionEdit';
import User from './Components/User';
import UserEdit from './Components/User/UserEdit';
import UserDashboard from './Components/User/UserDashboard';
import UserWidget from './Components/User/UserWidget';
import CourseMenu from './Components/CourseMenu';
import Video from './Components/Video';
import VideoEdit from './Components/Video/VideoEdit';

import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';
import Store from './Utils/Store';
import Teacher from "./Components/Teacher";
import { getToken } from './Utils/Common';
import UsersOnboarding from "./Components/User/UsersOnboarding";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const token = getToken();

  useEffect(() => {
    if (token) {
      setLoggedIn(true);
    }
  }, [token]);

  const loginCallback = (loggedInParam) => {
    setLoggedIn(loggedInParam);
  };

  return (
    <div className={ loggedIn ? 'bounceback-app' : 'bounceback-app no-auth' }>
      <Store>
        <BrowserRouter>
          <Container fluid>
            <Row>
              <Col className="sidebar" sm="12" md="4" lg="3" xl="2">
                <NavLink className="logo" exact activeClassName="active" to="/users/dashboard">
                  <div className="logo"></div>
                </NavLink>
                { loggedIn
                  ? <div className="admin-sidebar">
                      <NavLink activeClassName="active" to="/organizations">
                        <Button variant="flat">Organizations</Button>
                      </NavLink>
                      <NavLink activeClassName="active" to="/users">
                        <Button variant="flat">Users</Button>
                      </NavLink>
                      <NavLink activeClassName="active" to="/certificates">
                        <Button variant="flat">Certificates</Button>
                      </NavLink>
                      <CourseMenu />
                    </div>
                  : null }
              </Col>
              <Col sm="12" md="8" lg="9" xl="10" className="content-container">
                <div className="content">
                  <UserWidget loginCallback={loginCallback}></UserWidget>
                  <Switch>
                    <PrivateRoute exact path="/">
                      <Redirect to="/users/dashboard" />
                    </PrivateRoute>
                    <PublicRoute path="/login" component={() => <Login loginCallback={loginCallback} />} />
                    <PrivateRoute exact path="/organizations" component={Organization} />
                    <PrivateRoute path="/organizations/add" component={OrganizationEdit} />
                    <PrivateRoute path="/organizations/:id/edit" component={OrganizationEdit} />
                    <PrivateRoute path="/organizations/import/users" component={Teacher} />
                    <PrivateRoute exact path="/users" component={User} />
                    <PrivateRoute path="/users/add" component={UserEdit} />
                    <PrivateRoute path="/users/onboarding" component={UsersOnboarding} />
                    <PrivateRoute path="/users/dashboard" component={UserDashboard} />
                    <PrivateRoute path="/users/:id/edit" component={UserEdit} />
                    <PrivateRoute exact path="/certificates" component={Certificate} />
                    <PrivateRoute path="/certificates/add" component={CertificateEdit} />
                    <PrivateRoute path="/certificates/:id/edit" component={CertificateEdit} />
                    <PrivateRoute exact path="/courses" component={Course} />
                    <PrivateRoute path="/courses/add" component={CourseEdit} />
                    <PrivateRoute path="/courses/:id/edit" component={CourseEdit} />
                    <PrivateRoute exact path="/courses/:id/modules" component={Module} />
                    <PrivateRoute path="/courses/:id/modules/add" component={ModuleEdit} />
                    <PrivateRoute path="/courses/:id/modules/:module_id/edit" component={ModuleEdit} />
                    <PrivateRoute exact path="/modules/:id/sections" component={Section} />
                    <PrivateRoute path="/modules/:id/sections/add" component={SectionEdit} />
                    <PrivateRoute path="/modules/:id/sections/:section_id/edit" component={SectionEdit} />
                    <PrivateRoute exact path="/sections/:id/videos" component={Video} />
                    <PrivateRoute exact path="/sections/:id/videos/add" component={VideoEdit} />
                    <PrivateRoute exact path="/sections/:id/videos/:video_id/edit" component={VideoEdit} />
                    <PrivateRoute exact path="/sections/:id/knowledge_checks" component={KnowledgeCheck} />
                    <PrivateRoute exact path="/sections/:id/knowledge_checks/add" component={KnowledgeCheckEdit} />
                    <PrivateRoute exact path="/sections/:id/knowledge_checks/:knowledge_check_id/edit" component={KnowledgeCheckEdit} />
                  </Switch>
                </div>
              </Col>
            </Row>
          </Container>
        </BrowserRouter>
      </Store>
    </div>
  );
}

export default App;
