import React, { useContext, useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import organizationService from './OrganizationService';
import { Context } from '../../Utils/Store';

function Organization(props) {
  const history = useHistory();
  const [organizations, setOrganizations] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);

  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    if (state.search.length > 0) {
      organizationService.findBy('search', state.search).then(response => {
        setOrganizations(response.data);
        setIsEmpty(response.data.length === 0);
      }).catch(e => {
        dispatch({type: 'SET_ERROR', payload: e});
      });
    } else {
      organizationService.getAll().then(response => {
        setOrganizations(response.data);
        setIsEmpty(response.data.length === 0);
      });
    }
  }, [state.search, dispatch]);

  function navigateTo(route) {
    history.push(route);
  }

  return (
    <div className="organizations">
      <div id="actions" className="d-flex justify-content-end">
          { !isEmpty && <Button variant="flat" href='/organizations/import/users'>Upload users</Button>}
          <Button className={`ms-2`} variant="flat" href='/organizations/add'>Add Organization</Button>
      </div>

      <Table hover hidden={isEmpty}>
      <thead>
        <tr>
          <th>Organization</th>
          <th>Contact</th>
          <th>State</th>
          <th>City</th>
          <th>Date Joined</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {organizations.map((organization) => {
          return (
            <tr key={ organization.id }>
              <td><strong>{ organization.name }</strong></td>
              <td>{ organization.user ? organization.user.first_name : '' } { organization.user ? organization.user.last_name : '' }</td>
              <td>{ organization.state }</td>
              <td>{ organization.city }</td>
              <td>{ new Date(organization.created_at).toLocaleDateString() }</td>
              <td><Link to={'/organizations/' + organization.id + '/edit'}>Edit</Link></td>
            </tr>
          );
        })}
      </tbody>
      </Table>
      <div className="no-data" hidden={!isEmpty}>
        <img alt="schools icon" src="/images/school_icon.svg" />
        <div className="no-data-actions">
          <Button variant="flat" onClick={() => navigateTo('/organizations/add')}>Add Organization</Button>
        </div>
      </div>
    </div>
  );
}
 
export default Organization;