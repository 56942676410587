import 'react-bootstrap-typeahead/css/Typeahead.css';

import React, { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row, Table } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import organizationService from '../Organization/OrganizationService';
import userService from './UserService';

function UserEdit(props) {
  const history = useHistory();
  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState([]);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [notes, setNotes] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [show] = useState(true);

  const handleClose = () => history.goBack();
  const handleFirstName = (e) => setFirstName(e.target.value);
  const handleLastName = (e) => setLastName(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handleNotes = (e) => setNotes(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);
  const handlePasswordConfirmation = (e) => setPasswordConfirmation(e.target.value);
  
  const handleSave = () => {
    if (user) {
      userService.update(user, {
        user: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          notes: notes,
          tos_accepted: true,
        }
      });
      userService.setSchools(user, [organization[0].id]).then(response => {
        history.push('/users');
      });
    } else {
      userService.create({
        user: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          notes: notes,
          tos_accepted: true,
          password: password,
          password_confirmation: passwordConfirmation
        }
      }).then(response => {
        userService.setSchools(response.data.user.id, [organization[0].id]).then(r2 => {
          history.push('/users');
        });
      });
    }
  }

  useEffect(() => {
    userService.getAll().then(response => {
      setUsers(response.data);
    });
    organizationService.getAll().then(response => {
      setOrganizations(response.data);
    })
    if (props.match.params.id) {
      userService.get(props.match.params.id).then(response => {
        setUser(response.data.user.id);
        setFirstName(response.data.user.first_name);
        setLastName(response.data.user.last_name);
        setEmail(response.data.user.email);
        setNotes(response.data.user.notes ? response.data.user.notes : '');
        if (response.data.user.schools[0]) {
          setOrganization([response.data.user.schools[0]]);
        }
      });
    }
  }, [props.match.params.id]);

  return (
    <div id="user-edit">
      <div id="actions" className="float-end">
        <Button variant="flat" href='/users/add'>Add User</Button>
        </div>
      <Table hover>
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email Address</th>
          <th>Organization</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          return (
            <tr key={ user.id }>
              <td><strong>{ user.first_name }</strong></td>
              <td><strong>{ user.last_name }</strong></td>
              <td>{ user.email }</td>
              <td>{ user.schools.length > 0 ? user.schools[0].name : '-' }</td>
              <td><Link to={'/users/' + user.id + '/edit'}>Edit</Link></td>
            </tr>
          );
        })}
      </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ user ? 'Edit' : 'New' } User</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <FloatingLabel controlId="firstName" label="First Name">
                  <Form.Control value={firstName} onChange={handleFirstName} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="lastName" label="Last Name">
                  <Form.Control value={lastName} onChange={handleLastName} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel controlId="email" label="Email Address">
                  <Form.Control value={email} onChange={handleEmail} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="organization" label="Organization">
                  <Typeahead id="organization" className="form-control" labelKey="name"
                    onChange={(selected) => {
                      setOrganization(selected);
                    }}
                    options={organizations}
                    selected={organization}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className={user ? 'd-none' : ''}>
            <Col>
                <FloatingLabel controlId="password" label="Password">
                  <Form.Control type="password" value={password} onChange={handlePassword} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="passwordConfirmation" label="Password Confirmation">
                  <Form.Control type="password" value={passwordConfirmation} onChange={handlePasswordConfirmation} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel controlId="notes" label="Notes">
                  <Form.Control value={notes} onChange={handleNotes} />
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <Button variant="flat" style={{"width": '100%'}} onClick={handleSave}>
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
 
export default UserEdit;