/***
 * Usage
 1) Dynamic length
 CouponRandom.generate(8); // J5BST6NQ

 2) Using prefix
 CouponRandom.generate(6, ”XYZ-”); // XYZ-NT163E

 3) Using suffix
 CouponRandom.generate(6, ”XYZ-”, “-ABC”); // XYZ-TC2MSD-ABC

 4) Without numbers
 CouponRandom.generate(6, ””, ””, false); // LNTDRS

 5) Without letters
 CouponRandom.generate(6, ””, ””, true, false); // 835710

 6) With symbols
 CouponRandom.generate(6, ””, ””, true, true, true); // #H5&S!7

 7) Random register (includes lower and uppercase)
 CouponRandom.generate(6, ””, ””, true, true, false, true); // aT4hB2

 8) With custom Mask Note: length does not matter
 CouponRandom.generate(1, ””, ””, true, true, false, false, “XXXXXX”); // STG6N8
 *
 * */

function mt_rand (min, max)  {
    //  discuss at: https://locutus.io/php/mt_rand/
    // original by: Onno Marsman (https://twitter.com/onnomarsman)
    // improved by: Brett Zamir (https://brett-zamir.me)
    //    input by: Kongo
    //   example 1: mt_rand(1, 1)
    //   returns 1: 1
    const argc = arguments.length
    if (argc === 0) {
        min = 0
        max = 2147483647
    } else if (argc === 1) {
        throw new Error('Warning: mt_rand() expects exactly 2 parameters, 1 given')
    } else {
        min = parseInt(min, 10)
        max = parseInt(max, 10)
    }
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export class CouponRandom {
    static MIN_LENGTH = 8;

    /**
     * MASK FORMAT [XXX-XXX]
     * 'X' this is random symbols
     * '-' this is separator
     *
     * @param options
     * @return string
     * @throws Exception
     */
    static generate(options = []) {

        // to accept options as function arguments
        if (!Array.isArray((options) && arguments.length > 0)) {
            const keys = ['length', 'prefix', 'suffix', 'letters', 'numbers', 'symbols', 'mixed_case', 'mask'];
            let opt = [];

            for (const key in arguments) {
                opt[keys[key]] = arguments[key];
            }

            options = opt;
        }

        const length = (options.hasOwnProperty('length') ? parseInt(options['length']) && options['length'] > 0 ? parseInt(options['length']) : CouponRandom.MIN_LENGTH : CouponRandom.MIN_LENGTH);
        const prefix = (options.hasOwnProperty('prefix') ? options['prefix'] : '');
        const suffix = (options.hasOwnProperty('suffix') ? options['suffix'] : '');
        const useLetters = (options.hasOwnProperty('letters') ? options['letters'] : true);
        const useNumbers = (options.hasOwnProperty('numbers') ? options['numbers'] : false);
        const useSymbols = (options.hasOwnProperty('symbols') ? options['symbols'] : false);
        const useMixedCase = (options.hasOwnProperty('mixed_case') ? options['mixed_case'] : false);
        const mask = (options.hasOwnProperty('mask') ? options['mask'] : false);

        const uppercase = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Z', 'X', 'C', 'V', 'B', 'N', 'M'];
        const lowercase = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'z', 'x', 'c', 'v', 'b', 'n', 'm'];
        const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        const symbols = ['`', '~', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '_', '=', '+', '\\', '|', '/', '[', ']', '{', '}', '"', "'", ';', ':', '<', '>', ',', '.', '?'];

        let characters = [];
        let coupon = '';

        if (useLetters) {
            if (useMixedCase) {
                characters = [...characters, ...lowercase, ...uppercase];
            } else {
                characters = [...characters, ...uppercase];
            }
        }

        if (useNumbers) {
            characters = [...characters, ...numbers];
        }

        if (useSymbols) {
            characters = [...characters, ...symbols];
        }

        if (mask) {
            for (let i = 0; i < mask.length; i++) {
                if (mask[i] === 'X') {
                    coupon += characters[mt_rand(0, characters.length - 1)];
                } else {
                    coupon += mask[i];
                }
            }
        } else {
            for (let i = 0; i < length; i++) {
                coupon += characters[mt_rand(0, characters.length - 1)];
            }
        }

        return prefix + coupon + suffix;
    }

    /**
     * @param maxNumberOfCoupons
     * @param options
     * @return array
     */
    static generate_coupons(maxNumberOfCoupons = 1, options = []) {
        let coupons = [];
        for (let i = 0; i < maxNumberOfCoupons; i++) {
            coupons.push(CouponRandom.generate(...options));
        }
        return coupons;
    }


    /**
     * Strip all characters but letters and numbers
     * @param string
     * @param options
     * @return string
     * @throws Exception
     */
    static cleanString(string, options = []) {
        const toUpper = options.hasOwnProperty('uppercase') ? options['uppercase'] : false;
        const toLower = options.hasOwnProperty('lowercase') ? options['lowercase'] : false;

        const striped = string.trim().replace(/[^a-zA-Z0-9]/, '');

        // make uppercase
        if (toLower && toUpper) {
            throw new Error('You cannot set both options (uppercase|lowercase) to "true"!');
        } else if (toLower) {
            return striped.toLowerCase();
        } else if (toUpper) {
            return striped.toUpperCase();
        } else {
            return striped;
        }
    }
}