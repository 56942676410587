import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row, Table } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';

import sectionsService from './SectionService';
import { Context } from '../../Utils/Store';

function SectionEdit(props) {
  const history = useHistory();
  const [sections, setSections] = useState([{}]);
  const [module, setModule] = useState(0);
  const [section, setSection] = useState(0);
  const [name, setName] = useState('');
  const [order, setOrder] = useState(0);
  const [description, setDescription] = useState('');
  const [show] = useState(true);

  const [state, dispatch] = useContext(Context);
  
  useEffect(() => {
    if (props.match.params.section_id) {
      sectionsService.get(props.match.params.section_id).then(response => {
        setSection(response.data.id);
        setName(response.data.name);
        setOrder(response.data.order);
        setDescription(response.data.description);
      });
    }

    if (props.match.params.id) {
      setModule(props.match.params.id);
    }

    if (state.search.length > 0) {
      sectionsService.findBy(props.match.params.id, 'search', state.search).then(response => {
        setSections(response.data);
      }).catch(e => {
        dispatch({type: 'SET_ERROR', payload: e});
      });
    } else {
      sectionsService.getAll(props.match.params.id).then(response => {
        setSections(response.data);
      });
    }
  }, [props.match.params.id, state.search, dispatch, props.match.params.section_id]);

  const handleClose = () => history.goBack();
  const handleName = (e) => setName(e.target.value);
  const handleOrder = (e) => setOrder(+e.target.value);
  const handleDescription = (e) => setDescription(e.target.value);

  const handleSave = () => {
    if (section) {
      sectionsService.update(section, {
        module_section: {
          name: name,
          order: order,
          description: description,
          course_module_id: module
        }
      }).then(r => {
        history.push('/modules/' + props.match.params.id + '/sections');
      });
    } else {
      sectionsService.create({
        module_section: {
          name: name,
          order: order,
          description: description,
          course_module_id: module
        }
      }).then(r => {
        history.push('/modules/' + props.match.params.id + '/sections');
      });
    }
  }

  return (
    <div className="sections">
      <div id="title" className="float-start"><h1>Sections</h1></div>
      <div id="actions" className="float-end">
        <Button variant="flat" href={'/modules/' + props.match.params.id + '/sections/add'}>Add Section</Button>
      </div>
      <Table hover>
      <thead>
        <tr>
          <th>Section</th>
          <th>Videos</th>
          <th>Knowledge Checks</th>
          <th>Order</th>
          <th>Last Update</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {sections.map((section) => {
          return (
            <tr className={'r-' + section.id} key={ section.id }>
              <td><strong>{ section.name }</strong></td>
              <td>{ section.items ? section.items.length : 0 }</td>
              <td>{ section.knowledge_checks ? section.knowledge_checks.length : 0 }</td>
              <td>{ section.order }</td>
              <td>{ new Date(section.updated_at).toLocaleDateString() }</td>
              <td className="r-actions">
                <Link to={'/modules/' + props.match.params.id + '/sections/' + section.id + '/edit'}>Edit</Link>
                <Link to={'/sections/' + section.id + '/videos'}>Videos</Link>
                <Link to={'/sections/' + section.id + '/knowledge_checks'}>Knowledge Checks</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ module ? 'Edit' : 'New' } Section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <FloatingLabel controlId="name" label="Title">
                  <Form.Control value={name} onChange={handleName} />
                </FloatingLabel>
              </Col>

              <Col>
                <FloatingLabel controlId="order" label="Order">
                  <Form.Control value={order} type={'number'} onChange={handleOrder} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel controlId="description" label="Description">
                  <Form.Control value={description} onChange={handleDescription} />
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <Button variant="flat" style={{"width": '100%'}} onClick={handleSave}>
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

 
export default SectionEdit;