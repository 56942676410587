import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import sectionService from '../Section/SectionService';
import videosService from './VideoService';
import {convertSecondsToHrsMinsSec} from '../../Utils/Common';
import { Context } from '../../Utils/Store';

function Video(props) {
  const history = useHistory();
  const [videos, setVideos] = useState([{}]);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [videoId, setVideoId] = useState(0);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [parentSection, setParentSection] = useState('');

  const [state, dispatch] = useContext(Context);
  
  useEffect(() => {
    if (state.search.length > 0) {
      videosService.findBy(props.match.params.id, 'search', state.search).then(response => {
        setVideos(response.data);
      }).catch(e => {
        dispatch({type: 'SET_ERROR', payload: e});
      });
    } else {
      videosService.getAll(props.match.params.id).then(response => {
        setVideos(response.data);
      });
    }
    sectionService.get(props.match.params.id).then(r => setParentSection(r.data.name));
  }, [props.match.params.id, state.search, dispatch]);

  function handleVideoPlay(video)  {
    setVideoTitle(video.title);
    setVideoUrl(video.url);
    setIsVideoPlaying(true);
  }

  function handleVideoDelete(video) {
    setIsDeleteDialogVisible(true);
    setVideoId(video.id);
  }

  function handleClose() {
    setIsVideoPlaying(false);
    setIsDeleteDialogVisible(false);
  }

  function deleteVideo() {
    videosService.delete(videoId).then(r => {
      videosService.getAll(props.match.params.id).then(response => {
        setVideos(response.data);
        handleClose();
      });
    });
  }

  function navigateTo(route) {
    history.push(route);
  }

  return (
    <div className="videos">
      <div id="title" className="float-start"><h1>Videos - {parentSection}</h1></div>
      <div id="actions" className="float-end">
        <Button variant="flat" onClick={() => navigateTo('/sections/' + props.match.params.id + '/videos/add')}>Add Video</Button>
      </div>
      <Table hover>
      <thead>
        <tr>
          <th>Video Name</th>
          <th>Description</th>
          <th>Duration</th>
          <th>Rating</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {videos.map((video) => {
          return (
            <tr className={'r-' + video.id} key={ video.id }>
              <td>
                <img src="/images/video_img.png" alt="Video Icon" width="48" height="32" onClick={(e) => {handleVideoPlay(video)}} />
                <strong>{ video.title }</strong>
              </td>
              <td>{ video.description }</td>
              <td>{ convertSecondsToHrsMinsSec(video.duration) }</td>
              <td>{ video.rating }</td>
              <td className="r-actions">
                <Link to={'/sections/' + props.match.params.id + '/videos/' + video.id + '/edit'}>Edit</Link>
                <a href='#d' onClick={(e) => { handleVideoDelete(video) }}>Delete</a>
              </td>
            </tr>
          );
        })}
      </tbody>
      </Table>
      <Modal show={isVideoPlaying} fullscreen={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{videoTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video width="auto" height="100%" style={{margin: '0 auto', display: 'block'}} controls>
            <source src={videoUrl} type="video/mp4" />
          </video>
        </Modal.Body>
      </Modal>
      <Modal show={isDeleteDialogVisible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to delete it?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="danger" onClick={deleteVideo}>Yes</Button>{ ' ' }
          <Button variant="flat" onClick={handleClose}>No</Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
 
export default Video;