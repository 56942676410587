import 'react-bootstrap-typeahead/css/Typeahead.css';

import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, FloatingLabel, Form, Modal, Row, Spinner, Table, Toast, ToastContainer} from 'react-bootstrap';
import {useHistory, Link} from 'react-router-dom';
import {Typeahead} from 'react-bootstrap-typeahead';
import organizationService from '../Organization/OrganizationService';

import {Context} from "../../Utils/Store";
import {CouponRandom} from "../../Utils/Coupons";

class SchoolCodeRequest {
    id = null;
    qty = 1;
    school = [];
    description = ''
    download = ''

    constructor(props) {
        if (props) {
            this.id = props.id;
            this.qty = props.qty;
            this.school = props.school;
            this.description = props.description;
            this.download = props.download;
        }
    }
}

function UsersOnboarding(props) {
    const [schoolRequests, setSchoolRequests] = useState([]);
    const [school, setSchool] = useState(new SchoolCodeRequest());
    const [schoolDelete, setSchoolDelete] = useState(new SchoolCodeRequest());
    const [schoolList, setSchoolList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const [message, setMessage] = useState('');
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

    const history = useHistory();
    const [state, dispatch] = useContext(Context);

    const handleClose = () => {
        setShowModal(false);
    }

    const handleSave = () => {

        if (school.qty > 1000) {
            setMessage('Codes length must be greater than or equal to 1,000')
            setShowToast(true)
            return;
        }

        setIsLoading(true)

        // CouponRandom.generate_coupons(school.qty, [8, "ING-"]);
        let data = {
            qty: school.qty,
            description: school.description,
            school_id: school.school[0].id
        }

        organizationService.askCodesRequests(data).then((response) => {
            schoolRequests.push({...school, ...response.data})
            setSchool(new SchoolCodeRequest())
            setIsLoading(false)
            setShowModal(false)
        }, (err) => {
            setIsLoading(false)
        })
    }

    const downloadCodes = (school) => {
        organizationService.downloadCodes(school.download).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(`download`, `${school.school.name.replaceAll(' ','_')}-${new Date().toLocaleDateString().replaceAll('/','_')}-D${school.id}.csv`); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
    }

    const deleteRequest = () => {
        if (schoolDelete) {
            setIsLoading(true)
            const school_id = schoolDelete;
            organizationService.deleteCodesRequest(school_id).then((response) => {
                const tmp = [...schoolRequests.splice(schoolRequests.findIndex(i => i.id === school_id), 1)]
                setSchoolList(tmp.map(x => new SchoolCodeRequest(x)))
                setIsDeleteDialogVisible(false)
                setIsLoading(false)
                setSchoolDelete(null)
            }, (err) => {
                setIsDeleteDialogVisible(false)
                setIsLoading(false)
            })
        }
    }


    const confirmDelete = (school_id) => {
        setSchoolDelete(school_id)
        setIsDeleteDialogVisible(true)
    }

    const handleFormChange = (key, el) => {
        const {name, value} = el.target || {value: el};
        setSchool({...school, ...{[key]: value}})
    }

    const handleCloseDialog = () => {
        setIsDeleteDialogVisible(false)
        setSchoolDelete(null)
    }

    useEffect(() => {
        organizationService.getCodesRequests().then(response => {
            setSchoolRequests(response.data);
            setIsEmpty(response.data.length === 0);
        });

        organizationService.getAll().then(response => {
            setSchoolList(response.data);
        })
    }, []);

    return (
        <div id="user-edit">
            <div id="actions" className="d-flex justify-content-end">
                <Button onClick={() => setShowModal(!showModal)} variant="flat">Generate new codes</Button>
            </div>
            <Table hover>
                <thead>
                <tr>
                    <th>Organization</th>
                    <th>Codes</th>
                    <th>Description</th>
                    <th>Date created</th>
                    <th>Download</th>
                    <th>Delete</th>
                </tr>
                </thead>
                <tbody>
                {schoolRequests.map((school) => {
                    return (
                        <tr key={school.id}>
                            <td><strong>{school.school.name}</strong></td>
                            <td><strong>{school.qty}</strong></td>
                            <td>{school.description}</td>
                            <td>{new Date(school.created_at).toLocaleDateString()}</td>
                            <td><a href="#" onClick={(e) => {
                                e.preventDefault();
                                downloadCodes(school)
                            }}>Download</a></td>
                            <td><a className={`text-danger`} href="#"
                                   onClick={(e) => {
                                       e.preventDefault();
                                       confirmDelete(school.id)
                                   }}>Delete</a></td>
                        </tr>
                    );
                })}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Generate registration codes</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <FloatingLabel controlId="school" label="Organization">
                                    <Typeahead id="school" className="form-control" labelKey="name"
                                               onChange={(val) => {
                                                   if (val) {
                                                       handleFormChange('school', val)
                                                   }
                                               }}
                                               options={schoolList}
                                               selected={school.school}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel controlId="qty" label="Quantity (max. 1000)">
                                    <Form.Control type={'number'} name={`qty`} value={school.qty}
                                                  onChange={(val) => handleFormChange('qty', val)}/>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel controlId="description" label="Description">
                                    <Form.Control name={`description`} value={school.description}
                                                  onChange={(val) => handleFormChange('description', val)}/>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <br/>

                        <Button variant="flat" style={{"width": '100%'}} onClick={handleSave}>
                            {isLoading ? 'Generating' : 'Generate'} {isLoading && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"/>}
                        </Button>

                        {/*Toast*/}
                        <ToastContainer className="mt-3 w-100">
                            <Toast className={`w-100`} onClose={() => setShowToast(false)} show={showToast} delay={3000}
                                   autohide>
                                <Toast.Body>{message}</Toast.Body>
                            </Toast>
                        </ToastContainer>
                    </Form>
                </Modal.Body>
            </Modal>

            {/*Spinner or Blocking Modal*/}
            <Modal show={isLoading} fullscreen={true} keyboard={false} contentClassName={`bg-transparent`}>
            </Modal>

            {/*Modal to confirm delete*/}
            <Modal show={isDeleteDialogVisible} onHide={handleCloseDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure you want to delete it?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="danger" onClick={deleteRequest}>
                        {isLoading ? 'Deleting' : 'Yes'} {isLoading && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"/>}
                    </Button>{' '}
                    <Button variant="flat" onClick={handleCloseDialog}>No</Button>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default UsersOnboarding;