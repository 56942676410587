import axios from "axios";
import { getToken } from './Common';

class Api {
  getAxios() {
    return axios.create({
      baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${getToken()}`
      }
    });
  }
}

export default new Api();