import api from "../../Utils/Api";

class OrganizationService {
  getAll() {
    return api.getAxios().get("/schools");
  }

  get(id) {
    return api.getAxios().get(`/schools/${id}`);
  }

  create(data) {
    return api.getAxios().post("/schools", data);
  }

  update(id, data) {
    return api.getAxios().put(`/schools/${id}`, data);
  }

  delete(id) {
    return api.getAxios().delete(`/schools/${id}`);
  }

  findBy(field, value) {
    return api.getAxios().get(`/schools?${field}=${value}`);
  }

  getCodesRequests(school_id) {
    return api.getAxios().get(`/school_codes_requests?${school_id && 'school=' + school_id || ''}`);
  }

  askCodesRequests(data) {
    return api.getAxios().post(`/school_codes_requests`, data);
  }

  deleteCodesRequest(school_id) {
    return api.getAxios().delete(`/school_codes_requests/${school_id}`);
  }

  downloadCodes(uri) {
    return api.getAxios().get(uri, {responseType: 'blob'});
  }
}

export default new OrganizationService();