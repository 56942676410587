import {Button, Col, FloatingLabel, Modal, Row, Spinner, Table, Toast, ToastContainer} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import CSVReader from "react-csv-reader";
import userService from "../User/UserService";
import {Typeahead} from "react-bootstrap-typeahead";
import organizationService from "../Organization/OrganizationService";
import {validateEmail} from "../../Utils/Common";

function Teacher() {
    const [csvFile, setCsvFile] = useState();
    const [csvData, setCsvData] = useState();
    const [saved, setSaved] = useState(false);
    const [csvDataHeader, setCsvDataHeader] = useState();
    const [organization, setOrganization] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [invalidEmails, setInvalidEmails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [invalidCsv, setInvalidCsv] = useState(false);
    const [message, setMessage] = useState(``);
    const [emailIndex, setEmailIndex] = useState(2);

    useEffect(() => {
        organizationService.getAll().then(response => {
            setOrganizations(response.data);
        })
    }, []);

    const validateCsvData = (data, originalFile) => {
        setInvalidCsv(false);
        setMessage('');
        setShowToast(false)

        const emailField = data[0].indexOf('email')

        if (emailField < 0) {
            setEmailIndex(emailField);
            setMessage('CSV file needs to have an email column')
            setShowToast(true)
            return
        }

        setCsvDataHeader(data.shift());

        // Check invalid emails
        let invalidEmails = []
        data.map((row) => {
            if (!validateEmail(row[emailField])) {
                return invalidEmails.push(row[emailField])
            }
        });

        if (!!invalidEmails.length) {
            setMessage('There are one or more records with invalid email address')
            setShowToast(true)
        }

        setInvalidCsv(!!invalidEmails.length);
        setInvalidEmails(invalidEmails)
        setCsvFile(originalFile);
        setCsvData(data);
    }

    const handleSave = () => {

        if (!organization || !organization.length) {
            setMessage('Please select an organization to continue.')
            setShowToast(true);
            return
        }

        setLoading(true)
        setSaved(true);
        setShowToast(false);
        let formData = new FormData();
        formData.append("users_csv", csvFile);

        userService.sendUserFile(formData, organization[0].id).then((response) => {
            if (response.data.success) {
                setMessage(<><span>Users has been added. Go to <a
                    href={`/users`}> users list</a> to check them</span></>);
                setShowToast(true);
            }
            setLoading(false)
        }).catch(err => {
            setSaved(false);
            setLoading(false)
            console.log(err.response)
            if (err.response.data.message === 'UNIQ EMAIL') {
                setMessage(err.response.data.errors.message)
                const email = err.response.data.errors.message.match(/\(([^)]+)\)/)[1]
                document.getElementById(`teacher-${email}`).classList.add('bg-warning')
                setShowToast(true)
                return
            }

            setMessage('Something went wrong.')
        });
    }

    return (
        <>
            <Row>
                <Col>
                    <FloatingLabel controlId="organization" label="Organization">
                        <Typeahead id="organization" className="form-control" labelKey="name"
                                   onChange={(selected) => {
                                       if (selected) setOrganization(selected);
                                   }}
                                   placeholder={`Select an organization`}
                                   options={organizations}
                                   selected={organization}
                        />
                    </FloatingLabel>
                </Col>
                <Col xs={12} className="d-flex align-items-center justify-content-between">
                    <CSVReader onFileLoaded={(data, fileInfo, originalFile) => {
                        validateCsvData(data, originalFile);
                    }}/>

                    {csvData && <Button variant="flat" disabled={saved || invalidCsv} onClick={() => handleSave()}>
                        {loading ? 'Uploading' : 'Upload'} file {loading && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"/>}</Button>}
                </Col>

                <Col xs={12} className="mt-3">

                    {/*Toast*/}
                    <ToastContainer className="my-3 w-100">
                        <Toast className={`w-100`} onClose={() => setShowToast(false)} show={showToast}
                        >
                            <Toast.Header>
                                {message}
                            </Toast.Header>
                        </Toast>
                    </ToastContainer>

                    <Table hover hidden={!csvData}>
                        <thead>
                        <tr id={`head-x`}>
                            {csvDataHeader && csvDataHeader?.map((header, index) => <th
                                id={`th-${index}`}>{header}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            csvData && csvData?.map((row, index) => {
                                return (
                                    <tr className={`${invalidEmails.includes(row[emailIndex]) ? 'bg-warning' : ''}`}
                                        id={`teacher-${row[emailIndex]}`}>{row && row?.map(cell =>
                                        <td>{cell}</td>)}</tr>
                                );
                            })
                        }
                        </tbody>
                    </Table>
                </Col>

                {/*Spinner or Blocking Modal*/}
                <Modal show={loading} fullscreen={true} keyboard={false} contentClassName={`bg-transparent`}>
                </Modal>
            </Row>
        </>
    );
}

export default Teacher;