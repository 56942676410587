import React, { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row, Table } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import modulesService from './ModuleService';

function ModuleEdit(props) {
  const history = useHistory();

  const [modules, setModules] = useState([{}]);
  const [module, setModule] = useState(0);
  const [name, setName] = useState('');
  const [order, setOrder] = useState(0);
  const [length, setLength] = useState(0);
  const [course, setCourse] = useState(0);
  const [show] = useState(true);

  const handleClose = () => history.goBack();
  const handleName = (e) => setName(e.target.value);
  const handleOrder = (e) => setOrder(+e.target.value);
  const handleLength = (e) => setLength(e.target.value);
  
  const handleSave = () => {
    if (module) {
      modulesService.update(module, {
        course_module: {
          name: name,
          order: order,
          length: length,
          course_id: course
        }
      }).then(r => {
        history.push('/courses/' + props.match.params.id + '/modules');
      });
    } else {
      modulesService.create({
        course_module: {
          name: name,
          order: order,
          length: length,
          course_id: course
        }
      }).then(r => {
        history.push('/courses/' + props.match.params.id + '/modules');
      });
    }
  }

  useEffect(() => {
    try {
      modulesService.getAll(props.match.params.id).then(response => {
        setModules(response.data);
      });

      if (props.match.params.id) {
        setCourse(props.match.params.id);
      }

      if (props.match.params.module_id) {
        modulesService.get(props.match.params.module_id).then(response => {
          setModule(response.data.id);
          setName(response.data.name);
          setOrder(response.data.order);
          setLength(response.data.length);
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [props.match.params.id, props.match.params.module_id]);

  return (
    <div className="modules">
      <div id="title" className="float-start"><h1>Modules</h1></div>
      <div id="actions" className="float-end">
        <Button variant="flat" href={'/courses/' + props.match.params.id + '/modules/add'}>Add Module</Button>
      </div>
      <Table hover>
      <thead>
        <tr>
          <th>Module</th>
          <td>Order</td>
          <th>Hours</th>
          <th>Last Update</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {modules.map((module) => {
          return (
            <tr key={ module.id }>
              <td><strong>{ module.name }</strong></td>
              <td>{ module.order }</td>
              <td>{ module.length }</td>
              <td>{ new Date(module.updated_at).toLocaleString() }</td>
              <td className="r-actions">
                <Link to={'/courses/' + props.match.params.id + '/modules/' + module.id + '/edit'}>Edit</Link>
                <Link to={'/modules/' + module.id + '/sections'}>Sections</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ module ? 'Edit' : 'New' } Module</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <FloatingLabel controlId="name" label="Title">
                  <Form.Control value={name} onChange={handleName} />
                </FloatingLabel>
              </Col>

              <Col>
                <FloatingLabel controlId="order" label="Order">
                  <Form.Control value={order} onChange={handleOrder} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel controlId="length" label="Length">
                  <Form.Control value={length} onChange={handleLength} />
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <Button variant="flat" style={{"width": '100%'}} onClick={handleSave}>
              { module ? 'Edit' : 'New' } Module
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
 
export default ModuleEdit;