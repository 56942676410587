import 'react-bootstrap-typeahead/css/Typeahead.css';

import React, { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row, Table } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import userService from '../User/UserService';
import organizationService from './OrganizationService';

function OrganizationEdit(props) {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState(0);
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [notes, setNotes] = useState('');

  const [show] = useState(true);

  const handleClose = () => history.goBack();
  const handleName = (e) => setName(e.target.value);
  const handlePosition = (e) => setPosition(e.target.value);
  const handleState = (e) => setState(e.target.value);
  const handleCity = (e) => setCity(e.target.value);
  const handlePhone = (e) => setPhone(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handleNotes = (e) => setNotes(e.target.value);
  
  const handleSave = () => {
    if (organization) {
      organizationService.update(organization, {
        school: {
          name: name,
          user_id: user[0].id,
          state: state,
          city: city,
          phone: phone,
          position: position,
          notes: notes
        }
      }).then(response => {
        history.push('/organizations');
      });
    } else {
      organizationService.create({
        school: {
          name: name,
          user_id: user[0].id,
          state: state,
          city: city,
          phone: phone,
          position: position,
          notes: notes
        }
      }).then(response => {
        history.push('/organizations');
      });
    }
  }

  useEffect(() => {
    userService.getAll().then(response => {
      setUsers(response.data);
    });
    organizationService.getAll().then(response => {
      setOrganizations(response.data);
    })
    if (props.match.params.id) {
      organizationService.get(props.match.params.id).then(response => {
        setOrganization(response.data.id);
        if (response.data.user) {
          setUser([response.data.user]);
        }
        setName(response.data.name);
        setState(response.data.state ? response.data.state : '');
        setCity(response.data.city ? response.data.city : '');
        setPosition(response.data.position ? response.data.position : '');
        setPhone(response.data.phone ? response.data.phone : '')
        setEmail(response.data.user ? response.data.user.email : '');
        setNotes(response.data.notes ? response.data.notes : '');
      });
    }
  }, [props.match.params.id]);

  return (
    <div id="organization-edit">
      <div id="actions" className="float-end">
        <Button variant="flat" href='/organizations/add'>Add Organization</Button>
        </div>
      <Table hover>
      <thead>
        <tr>
          <th>Organization</th>
          <th>Contact</th>
          <th>State</th>
          <th>City</th>
          <th>Date Joined</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {organizations.map((organization) => {
          return (
            <tr key={ organization.id }>
              <td><strong>{ organization.name }</strong></td>
              <td>{ organization.user ? organization.user.first_name : '' } { organization.user ? organization.user.last_name : '' }</td>
              <td>{ organization.state }</td>
              <td>{ organization.city }</td>
              <td>{ new Date(organization.created_at).toLocaleDateString() }</td>
              <td><Link to={'/organizations/' + organization.id + '/edit'}>Edit</Link></td>
            </tr>
          );
        })}
      </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ organization ? 'Edit' : 'New' } Organization</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <FloatingLabel controlId="name" label="Organization name">
                  <Form.Control value={name} onChange={handleName} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel controlId="contact" label="Contact">
                  <Typeahead id="contact" className="form-control" labelKey="email"
                    onChange={(selected) => {
                      setUser(selected);
                    }}
                    options={users}
                    selected={user}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="position" label="Position">
                  <Form.Control value={position} onChange={handlePosition} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel controlId="state" label="State">
                  <Form.Control value={state} onChange={handleState} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="city" label="City">
                  <Form.Control value={city} onChange={handleCity} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel controlId="phone" label="Phone Number">
                  <Form.Control value={phone} onChange={handlePhone} />
                </FloatingLabel>
              </Col>
              <Col>
              <FloatingLabel controlId="email" label="Email Address">
                  <Form.Control value={email} onChange={handleEmail} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel controlId="notes" label="Notes">
                  <Form.Control value={notes} onChange={handleNotes} />
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <Button variant="flat" style={{"width": '100%'}} onClick={handleSave}>
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
 
export default OrganizationEdit;