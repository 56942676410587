import api from "../../Utils/Api";

class KnowledgeCheckService {
  getAll(sectionId) {
    return api.getAxios().get(`/knowledge_checks?module_section_id=${sectionId}`);
  }

  get(id) {
    return api.getAxios().get(`/knowledge_checks/${id}`);
  }

  create(data) {
    return api.getAxios().post("/knowledge_checks", data);
  }

  update(id, data) {
    return api.getAxios().put(`/knowledge_checks/${id}`, data);
  }

  delete(id) {
    return api.getAxios().delete(`/knowledge_checks/${id}`);
  }

  findBy(sectionId, field, value) {
    return api.getAxios().get(`/knowledge_checks?module_section_id=${sectionId}&${field}=${value}`);
  }

  createQuestion(data) {
    return api.getAxios().post("/knowledge_check_questions", data);
  }

  createQuestionOption(data) {
    return api.getAxios().post("/knowledge_check_options", data);
  }

  updateQuestion(id, data) {
    return api.getAxios().put(`knowledge_check_questions/${id}`, data);
  }

  updateQuestionOption(id, data) {
    return api.getAxios().put(`knowledge_check_options/${id}`, data);
  }
}

export default new KnowledgeCheckService();