import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Button, Col, FloatingLabel, Form, Modal, Row, Table, Spinner} from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import {useDropzone} from 'react-dropzone'

import {convertSecondsToHrsMinsSec} from '../../Utils/Common';
import {Context} from '../../Utils/Store';
import videosService from './VideoService';

const initBeforeUnLoad = (showExitPrompt) => {
  window.onbeforeunload = (event) => {
    // Show prompt based on state
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = 'Your video is still uploading, do you want to quit anyway?'
      }
      return 'Your video is still uploading, do you want to quit anyway?';
    }
  };
}

function VideoEdit(props) {
  const history = useHistory();
  const [videos, setVideos] = useState([{}]);
  const [section, setSection] = useState(0);
  const [video, setVideo] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [duration, setDuration] = useState(0.0);
  const [rating, setRating] = useState(0.0);
  const [show] = useState(true);
  const [isSavingVideo, setIsSavingVideo] = useState(false);
  const [formData, setFormData] = useState(null);

  const [state, dispatch] = useContext(Context);

  window.onload = function() {
    initBeforeUnLoad(isSavingVideo);
  };

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad(isSavingVideo);
  }, [isSavingVideo]);

  useEffect(() => {
    if (props.match.params.video_id) {
      videosService.get(props.match.params.video_id).then(response => {
        setVideo(response.data.id);
        setTitle(response.data.title);
        setDescription(response.data.description);
        setDuration(response.data.duration);
        setRating(response.data.rating);
      });
    }

    if (props.match.params.id) {
      setSection(props.match.params.id);
    }

    if (state.search.length > 0) {
      videosService.findBy(props.match.params.id, 'search', state.search).then(response => {
        setVideos(response.data);
      }).catch(e => {
        dispatch({type: 'SET_ERROR', payload: e});
      });
    } else {
      videosService.getAll(props.match.params.id).then(response => {
        setVideos(response.data);
      });
    }
  }, [props.match.params.id, state.search, dispatch, props.match.params.video_id]);

  const onDrop = useCallback(acceptedFiles => {
    /***
     * Set video duration property
     * @param file
     */
    const calculateVideoDuration = (file) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        handleDuration(video.duration);
      }

      video.src = URL.createObjectURL(file);
    }

    const data = new FormData();
    data.append("video", acceptedFiles[0]);
    setFormData(data);

    // Calculate video duration
    calculateVideoDuration(acceptedFiles[0]);

  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const handleClose = () => history.goBack();
  const handleTitle = (e) => setTitle(e.target.value);
  const handleDescription = (e) => setDescription(e.target.value);
  const handleDuration = (value) => setDuration(value);

  const handleSave = () => {
    if (video) {
      videosService.update(video, {
        module_section_video: {
          title: title,
          description: description,
          duration: duration,
          rating: rating,
          module_section_id: section
        }
      }).then(async response => {
        setIsSavingVideo(true);
        await videosService.upload(video, formData);
        setIsSavingVideo(false);
        history.push('/sections/' + props.match.params.id + '/videos');
      });
    } else {
      videosService.create({
        module_section_video: {
          title: title,
          description: description,
          duration: duration,
          rating: rating,
          module_section_id: section
        }
      }).then(async response => {
        setIsSavingVideo(true);
        await videosService.upload(response.data.id, formData);
        setIsSavingVideo(false);
        history.push('/sections/' + props.match.params.id + '/videos');
      });
    }
  }

  return (
    <div className="videos">
      <div id="title" className="float-start"><h1>Videos</h1></div>
      <div id="actions" className="float-end">
        <Button variant="flat" href={'/sections/' + props.match.params.id + '/videos/add'}>Add Video</Button>
      </div>
      <Table hover>
      <thead>
        <tr>
          <th>Video Name</th>
          <th>Description</th>
          <th>Duration</th>
          <th>Rating</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {videos.map((video) => {
          return (
            <tr className={'r-' + video.id} key={ video.id }>
              <td>
                <img src="/images/video_img.png" alt="Video Icon" width="48" height="32" />
                <strong>{ video.title }</strong>
              </td>
              <td>{ video.description }</td>
              <td>{ convertSecondsToHrsMinsSec(video.duration) }</td>
              <td>{ video.rating }</td>
              <td className="r-actions">
                <Link to={'/sections/' + props.match.params.id + '/videos/' + video.id + '/edit'}>Edit</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
      </Table>
      {/*Form*/}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ video ? 'Edit' : 'New' } Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <FloatingLabel controlId="title" label="Title">
                  <Form.Control value={title} onChange={handleTitle} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel controlId="description" label="Description">
                  <Form.Control as="textarea" rows={3} style={{ height: '100px' }} value={description} onChange={handleDescription} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
              <div className="drop-area" {...getRootProps()}>
                <input id="videoFile" {...getInputProps()} />
                <img src="/images/image_icon.png" alt="File icon" width="36" height="36" />
                {
                  isDragActive ?
                    <p>Drop the files here ...</p>
                    : <p><strong>Upload a file</strong> or drag and drop</p>
                }
                <aside>
                  {formData ? formData.get('video').name : ''}
                </aside>
              </div>
              </Col>
            </Row>
            <br/>
            <Button disabled={isSavingVideo} variant="flat" style={{"width": '100%'}} onClick={handleSave}>
              { isSavingVideo ? 'Saving' : 'Save' } { isSavingVideo && <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true" />}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {/*Spinner or Blocking Modal*/}
      <Modal show={isSavingVideo} fullscreen={true} keyboard={false} contentClassName={`bg-transparent`}>
      </Modal>
    </div>
  );
}


export default VideoEdit;