import api from "../../Utils/Api";

class VideoService {
  getAll(sectionId) {
    return api.getAxios().get(`/module_section_videos?module_section_id=${sectionId}`);
  }

  get(id) {
    return api.getAxios().get(`/module_section_videos/${id}`);
  }

  create(data) {
    return api.getAxios().post("/module_section_videos", data);
  }

  update(id, data) {
    return api.getAxios().put(`/module_section_videos/${id}`, data);
  }

  delete(id) {
    return api.getAxios().delete(`/module_section_videos/${id}`);
  }

  findBy(sectionId, field, value) {
    return api.getAxios().get(`/module_section_videos?module_section_id=${sectionId}&${field}=${value}`);
  }

  upload(id, formData) {
    return api.getAxios().post(`module_section_videos/${id}/upload`, formData);
  }
}

export default new VideoService();