import 'react-bootstrap-typeahead/css/Typeahead.css';

import React, { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row, Table } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import certificateService from './CertificateService';
import organizationService from '../Organization/OrganizationService';

function CourseEdit(props) {
  const history = useHistory();
  
  const [schools, setSchools] = useState([]);
  const [school, setSchool] = useState([]);
  const [certificates, setCourses] = useState([]);
  const [course, setCourse] = useState(0);
  const [name, setName] = useState('');
  const [show] = useState(true);

  const handleClose = () => history.goBack();
  const handleName = (e) => setName(e.target.value);
  
  const handleSave = () => {
    if (course) {
      certificateService.update(course, {
        course: {
          name: name,
          school_id: school[0].id
        }
      }).then(response => {
        history.push('/certificates');
      });
    } else {
      certificateService.create({
        course: {
          name: name,
          school_id: school[0].id
        }
      }).then(response => {
        history.push('/certificates');
      });
    }
  }

  useEffect(() => {
    certificateService.getAll().then(response => {
      setCourses(response.data);
    });
    organizationService.getAll().then(response => {
      setSchools(response.data);
    })
    if (props.match.params.id) {
      certificateService.get(props.match.params.id).then(response => {
        setCourse(response.data.id);
        setName(response.data.name);
        setSchool(response.data.school ? [response.data.school] : []);
      });
    }
  }, [props.match.params.id]);

  return (
    <div id="course-edit">
      <div id="actions" className="float-end">
        <Button variant="flat" href='/certificates/add'>Add Certificate</Button>
        </div>
      <Table hover>
      <thead>
        <tr>
          <th>Certificates</th>
          <th>Last Update</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {certificates.map((certificate) => {
          return (
            <tr key={ certificate.id }>
              <td><strong>{ certificate.name }</strong></td>
              <td>{ new Date(certificate.updated_at).toLocaleDateString() }</td>
              <td className="r-actions">
                <Link to={'/certificates/' + certificate.id + '/edit'}>Edit</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ course ? 'Edit' : 'New' } Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <FloatingLabel controlId="name" label="Certificate Name">
                  <Form.Control value={name} onChange={handleName} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="school" label="Organization">
                  <Typeahead id="school" className="form-control" labelKey="name"
                    onChange={(selected) => {
                      setSchool(selected);
                    }}
                    options={schools}
                    selected={school}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <Button variant="flat" style={{"width": '100%'}} onClick={handleSave}>
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
 
export default CourseEdit;