import api from "../../Utils/Api";

class ModuleService {
  getAll(courseId) {
    return api.getAxios().get(`/course_modules?course_id=${courseId}`);
  }

  get(id) {
    return api.getAxios().get(`/course_modules/${id}`);
  }

  create(data) {
    return api.getAxios().post("/course_modules", data);
  }

  update(id, data) {
    return api.getAxios().put(`/course_modules/${id}`, data);
  }

  delete(id) {
    return api.getAxios().delete(`/course_modules/${id}`);
  }

  findBy(courseId, field, value) {
    return api.getAxios().get(`/course_modules?course_id=${courseId}&${field}=${value}`);
  }
}

export default new ModuleService();