import React, { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import certificateService from './CertificateService';
import {UserCertificate} from "./models";

function Certificate(props) {
  const [certificates, setCertificates] = useState([new UserCertificate()]);
  const [isEmpty, setIsEmpty] = useState(true);
  const history = useHistory();

  useEffect(() => {
    try {
      certificateService.getAll().then(response => {
        const userCertificates = response.data.map(x => new UserCertificate(x));
        setCertificates(userCertificates);
        setIsEmpty(userCertificates.length === 0);
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  function navigateTo(route) {
    history.push(route);
  }

  const downloadCertificate = (certificate)=>{
      certificateService.downloadCertificates(`${certificate.url}?user=${certificate.user.id}`).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `certificate_${certificate.user.full_name.replaceAll(' ', '_')}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
      })
      .catch((error) => console.log(error));
  }

  return (
    <div>
      <div id="actions" className="float-end" hidden={isEmpty}>
        {/*<Button variant="flat" onClick={() => navigateTo('/certificates/add')}>Add Certificate</Button>*/}
      </div>
      <Table hidden={isEmpty} hover>
      <thead>
        <tr>
          <th>User</th>
          <th>Course</th>
          <th>Date Created</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {certificates.map((certificate) => {
          return (
            <tr key={ certificate.id }>
              <td>{ certificate?.user?.full_name }</td>
              <td>{ certificate?.course?.name }</td>
              <td>{ new Date(certificate.created_at).toLocaleDateString() }</td>
              <td><a href="#" onClick={(e) => { e.preventDefault(); downloadCertificate(certificate)}}>Download</a></td>
            </tr>
          );
        })}
      </tbody>
      </Table>
      <div className="no-data" hidden={!isEmpty}>
        <img alt="certificates icon" src="/images/certification_icon.svg" />
        <div className="no-data-actions">
          <Button variant="flat">No data</Button>
        </div>
      </div>
    </div>
  );
}

export default Certificate;