import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Dropdown, FloatingLabel, Form, Row } from 'react-bootstrap';
import { BsArrowLeft, BsX } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import kcsService from './KnowledgeCheckService';
import { Context } from '../../Utils/Store';

function KnowledgeCheckEdit(props) {
  const history = useHistory();
  const [section, setSection] = useState(0);
  const [knowledgeCheck, setKnowledgeCheck] = useState(0);
  const [name, setName] = useState('');

  const [inputList, setInputList] = useState([
    {
      id: 0,
      name: "",
      multiple: false,
      type: 'Unique',
      answers: [
        {
          id: 0,
          name: "",
          correct: false
        },
        {
          id: 0,
          name: "",
          correct: false
        },
        {
          id: 0,
          name: "",
          correct: false
        },
        {
          id: 0,
          name: "",
          correct: false
        }
      ]
    }
  ]);

  const [state, dispatch] = useContext(Context);
  
  useEffect(() => {
    if (props.match.params.knowledge_check_id) {
      kcsService.get(props.match.params.knowledge_check_id).then(response => {
        setKnowledgeCheck(response.data.id);
        setName(response.data.name);
        if (response.data) {
          const questions = [];
          response.data.knowledge_check_questions_options_answers.forEach(qoa => {
            const question = {
              id: qoa.id,
              name: qoa.name,
              multiple: qoa.multiple ? qoa.multiple : false,
              answers: []
            };
            qoa.knowledge_check_options.forEach(o => {
              question.answers.push({
                id: o.id,
                name: o.name,
                correct: o.correct ? o.correct : false
              })
            });
            question.answers.sort(function (a, b) {
              return ('' + a.name).localeCompare(b.name);
            })
            questions.push(question);
          });
          setInputList(questions);
        }
      });
    }

    if (props.match.params.id) {
      setSection(props.match.params.id);
    }
  }, [props.match.params.id, state.search, dispatch, props.match.params.knowledge_check_id]);

  const handleClose = () => history.goBack();
  const handleName = (e) => setName(e.target.value);

  const handleAddQuestion = (e) => {
    const list = [...inputList];
    list.push({
      id: 0,
      name: "",
      multiple: false,
      answers: [
        {
          id: 0,
          name: "",
          correct: false
        },
        {
          id: 0,
          name: "",
          correct: false
        },
        {
          id: 0,
          name: "",
          correct: false
        },
        {
          id: 0,
          name: "",
          correct: false
        }
      ]
    });
    setInputList(list);
  };

  const deleteQuestion = (i) => {
    const list = [...inputList];
    list.splice(i, 1);
    setInputList(list);
  };

  const deleteAnswer = (i, j) => {
    const list = [...inputList];
    list[i].answers.splice(j, 1);
    setInputList(list);
  };

  const handleSave = () => {
    if (knowledgeCheck) {
      kcsService.update(knowledgeCheck, {
        knowledge_check: {
          name: name,
          module_section_id: section
        }
      }).then(async r => {
        await Promise.all(inputList.map(async question => {
          if (question.id) {
            await kcsService.updateQuestion(question.id, {
              knowledge_check_question: {
                  name: question.name,
                  multiple: question.multiple,
                  knowledge_check_id: knowledgeCheck
              }
            });
            await Promise.all(question.answers.map(async answer => {
              await kcsService.updateQuestionOption(answer.id, {
                knowledge_check_option: {
                  name: answer.name,
                  correct: answer.correct,
                  knowledge_check_question_id: question.id
                }
              });
            }));

          } else {
            const questionResp = await kcsService.createQuestion({
              knowledge_check_question: {
                  name: question.name,
                  multiple: question.multiple,
                  knowledge_check_id: knowledgeCheck
              }
            });
            await Promise.all(question.answers.map(async answer => {
              await kcsService.createQuestionOption({
                knowledge_check_option: {
                  name: answer.name,
                  correct: answer.correct,
                  knowledge_check_question_id: questionResp.data.id
                }
              });
            }));

          }
        }));
        history.push('/sections/' + props.match.params.id + '/knowledge_checks');
      });
    } else {
      kcsService.create({
        knowledge_check: {
          name: name,
          module_section_id: section
        }
      }).then(async response => {
        const knowledgeCheckId = response.data.id;
        setKnowledgeCheck(knowledgeCheckId);
        await Promise.all(inputList.map(async question => {
          const newQuestionResponse = await kcsService.createQuestion({
            knowledge_check_question: {
                name: question.name,
                multiple: question.multiple,
                knowledge_check_id: knowledgeCheckId
            }
          });
          await Promise.all(question.answers.map(async answer => {
            await kcsService.createQuestionOption({
              knowledge_check_option: {
                name: answer.name,
                correct: answer.correct,
                knowledge_check_question_id: newQuestionResponse.data.id
              }
            });
          }));
        }));
        history.push('/sections/' + props.match.params.id + '/knowledge_checks');
      });
    }
  }

  return (
    <div className="knowledge-check-edit">
      <Button variant="flat" className="kc-button-back" onClick={handleClose}>
        <BsArrowLeft /> Back
      </Button>
      <Form>
        <Row>
          <Col>
            <FloatingLabel controlId="title" label="Knowledge Check Title">
              <Form.Control value={name} onChange={handleName} key={'kc-title'} />
            </FloatingLabel>
          </Col>
        </Row>

        <Row>
          <Col>
            {inputList.map((question, i) => {
              return (
                <div className="box" key={ 'question-row-' + i }>
                  <Row>
                    <Col>
                      <strong>Question {i+1}</strong>
                    </Col>
                    <Col>
                      <Dropdown className="float-end" key={ 'question-dropdown-' + i }>
                          <Dropdown.Toggle className="dropdown-basic">
                            Answer Type
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item key={'dropdown-item-t1-' + question.id} active={question.multiple} onClick={(e) => { question.multiple = true; setInputList([...inputList]); }}>Multiple Selection</Dropdown.Item>
                            <Dropdown.Item key={'dropdown-item-t2-' + question.id} active={!question.multiple} onClick={(e) => { question.multiple = false; setInputList([...inputList]); }}>Unique Selection</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col xs="2" xl="1">
                      <Button variant="flat" className="kc-button-back" onClick={e => deleteQuestion(i)}>
                        <BsX />
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    <FloatingLabel controlId="question" label="Question">
                      <Form.Control
                        defaultValue={ question.name }
                        onChange={(e) => question.name = e.target.value }
                        key={ 'question-input-' + i } />
                    </FloatingLabel>
                    </Col>
                  </Row>
                  {question.answers.map((answer, j) => {
                    return (
                      <Row>
                        <Col>
                          <FloatingLabel controlId="answer" label="Answer">
                            <Form.Control
                              key={'input-answer-' + (answer.id ? answer.id : j)}
                              defaultValue={answer.name}
                              onChange={(e) => answer.name = e.target.value } />
                          </FloatingLabel>
                        </Col>
                        <Col md="2" xl="1">
                          { question.multiple ?
                            <Form.Check
                              type="checkbox"
                              aria-label={`Check if the answer "${answer.name}" is correct `}
                              key={'checkbox-answer-' + (answer.id ? answer.id : j)}
                              defaultChecked={answer.correct}
                              onChange={(e) => answer.correct = e.target.checked } /> :
                            <Form.Check
                              type="radio"
                              name={'group_' + question.id}
                              aria-label={`Check if the answer "${answer.name}" is correct `}
                              key={'checkbox-answer-' + (answer.id ? answer.id : j)}
                              defaultChecked={answer.correct}
                              onChange={(e) => answer.correct = e.target.checked } />
                          }
                        </Col>
                        <Col md="2" xl="1">
                          <Button variant="flat" className="kc-button-back" onClick={e => deleteAnswer(i, j)}>
                            <BsX />
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                  <Row>
                    <Col>
                      <Button variant="flat" className="float-end" onClick={handleSave}>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </Col>
        </Row>
        <br/>
        <Button variant="flat" className="kc-button" onClick={handleAddQuestion}>
          Add Questions
        </Button>
      </Form>
    </div>
  );
}

 
export default KnowledgeCheckEdit;