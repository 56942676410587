export class UserCertificate{
    id = null;
    name = '';
    user = null;
    created_at = '';
    updated_at = '';
    url = '';
    course = null;

    constructor(props) {
        if(props){
            this.id = props.id;
            this.name = props.name;
            this.user = new User(props.user);
            this.created_at = props.created_at;
            this.updated_at = props.updated_at;
            this.url = props.url;
            this.course = new Course(props.course);
        }
    }
}

class User{
    id = null;
    email = '';
    image = null;
    bio = null;
    provider = null;
    uid = null;
    first_name = '';
    last_name = '';
    school_id = null;
    tos_accepted = null;
    notes = null;
    require_new_password = false;
    created_at = '';
    updated_at = '';
    full_name = '';

    constructor(props) {
        if(props){
            this.id = props.id;
            this.email = props.email;
            this.image = props.image;
            this.bio = props.bio;
            this.provider = props.provider;
            this.uid = props.uid;
            this.first_name = props.first_name;
            this.last_name = props.last_name;
            this.full_name = `${props.first_name} ${props.last_name}`;
            this.school_id = props.school_id;
            this.tos_accepted = props.tos_accepted;
            this.notes = props.notes;
            this.require_new_password = props.require_new_password;
            this.created_at = props.created_at;
            this.updated_at = props.updated_at;
        }
    }
}

class Course{
    id = null;
    name = ''
    created_at = ''
    updated_at = ''
    status = false;
    activity_count = null

    constructor(props) {
        if(props){
            this.id = props.id;
            this.name = props.name;
            this.created_at = props.created_at;
            this.updated_at = props.updated_at;
            this.status = props.status;
            this.activity_count = props.activity_count;
        }
    }
}